// import './ArticleCard.scss';

import * as React from 'react';
import {Divider, Tag} from 'antd';
import { format } from 'date-fns';
import Dotdotdot from "react-dotdotdot";
import { Article } from '../../../interfaces/article.interface';


interface ArticleCardProps {
  article: Article;
  url?: string;
  clicked?: () => void;
}


class ArticleCard extends React.Component<ArticleCardProps> {

  renderCategoryTags() {
    return this.props.article.categories.map((category, categoryIndex) => {
      return <Tag className={`${categoryIndex === 0? 'primary': 'textColor2'}`} key={categoryIndex}>{category.name}</Tag>
    });
  }

  emitClicked(): void {
    if(this.props?.clicked) {
      this.props?.clicked();
    }
  }

  render() {
    const image = (this.props?.article?.image) ? <img src={this.props?.article?.image} alt="Article Card" loading="lazy"/> : <div className="empty-image-placeholder" />;

    return (
      <div className="ArticleCard">
        <div className="image-container" onClick={() => { this.emitClicked() }}>
          {image}
        </div>
        <div className="details">
          <div className="categories">
            {this.renderCategoryTags()}
          </div>
          <Dotdotdot className="title typo-title" clamp={2}>
            <a href={this.props?.url}>{this.props.article.name}</a>
          </Dotdotdot>
          <div className="date-text">{format((this.props.article.publishedAt? this.props.article.publishedAt: new Date()), 'MMMM dd, yyyy')}</div>
          <Dotdotdot className="content-text" clamp={6}><div>{this.props?.article?.content.replace(/<sub>(.*?)<\/sub>/ig, '').replace(/(<([^>]+)>)/ig, '')}</div></Dotdotdot>
        </div>
        <Divider />
      </div>
    );
  }
}

export default ArticleCard;