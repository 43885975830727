// import './ArticleDetailsPage.scss';

import * as React from 'react';
import { read_cookie } from 'sfcookies';
import {startCase} from "lodash";
import {
  Button,
  Menu,
  notification,
  Tag,
  Divider,
  Input,
  TreeSelect,
  Image,
  Form,
  Comment as AntComment,
  Collapse,
  Card,
  Col,
  Row, FormInstance
} from 'antd';
import Icon, {HeartFilled, FacebookFilled, InstagramOutlined, MailFilled, DownOutlined} from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import {format} from "date-fns";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import { Article } from '../../../interfaces/article.interface';
import { User } from '../../../interfaces/user.interface';
import UserApi from '../../../api-services/user-api.service';
import ArticleApi from '../../../api-services/article-api.service';
import Footer from '../../basic-components/footer/Footer';
import Navbar from '../../basic-components/navbar/Navbar';
import ArticleCard from '../../basic-components/article-card/ArticleCard';
import BreadcrumbsNavigation from '../../basic-components/breadcrumbs-navigation/BreadcrumbsNavigation';
import LoginModal from '../../modals/login-modal/LoginModal';
import GalleryModal from '../../modals/gallery-modal/GalleryModal';
import ForgotPasswordModal from '../../modals/forgot-password-modal/ForgotPasswordModal';
import {BlogBanner} from "../../../interfaces/blog-banner.interface";
import BlogBannerApi from "../../../api-services/blog-banner-api.service";
import {BlogCategory} from "../../../interfaces/blog-category.interface";
import {Comment} from "../../../interfaces/comment.interface";
import BlogCategoryApi from "../../../api-services/blog-category-api.service";
import CommentApi from "../../../api-services/comment-api.service";


const commentOutlineSVG = () => (<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
  <path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10M6,7H18V9H6V7M6,11H15V13H6V11Z" />
</svg>);
const pinterestSVG = () => (<svg width="18" height="18" viewBox="0 0 24 24" fill="currentColor"><path d="M9.04,21.54C10,21.83 10.97,22 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12C2,16.25 4.67,19.9 8.44,21.34C8.35,20.56 8.26,19.27 8.44,18.38L9.59,13.44C9.59,13.44 9.3,12.86 9.3,11.94C9.3,10.56 10.16,9.53 11.14,9.53C12,9.53 12.4,10.16 12.4,10.97C12.4,11.83 11.83,13.06 11.54,14.24C11.37,15.22 12.06,16.08 13.06,16.08C14.84,16.08 16.22,14.18 16.22,11.5C16.22,9.1 14.5,7.46 12.03,7.46C9.21,7.46 7.55,9.56 7.55,11.77C7.55,12.63 7.83,13.5 8.29,14.07C8.38,14.13 8.38,14.21 8.35,14.36L8.06,15.45C8.06,15.62 7.95,15.68 7.78,15.56C6.5,15 5.76,13.18 5.76,11.71C5.76,8.55 8,5.68 12.32,5.68C15.76,5.68 18.44,8.15 18.44,11.43C18.44,14.87 16.31,17.63 13.26,17.63C12.29,17.63 11.34,17.11 11,16.5L10.33,18.87C10.1,19.73 9.47,20.88 9.04,21.57V21.54Z" /></svg>);
const CommentOutlineIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={commentOutlineSVG} {...props} />
);
const PinterestIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={pinterestSVG} {...props} />
);

// Swiper.use([Navigation]);
SwiperCore.use([Navigation]);
notification.config({
  duration: 12,
  top: 60
})

interface ArticleDetailsPageProps {
  isAccountSignedIn: boolean;
  googleSignInUrl: string;
  fbSignInUrl: string;
  csrfToken:string;
  articleData?: any;
}

interface ArticleDetailsPageState {
  article: Article;
  loggedOnUser: User;
  loginModalVisible: boolean;
  loginModalRedirectUrl: string;
  relatedArticles: Article[];
  breadcrumbsNavItems: Array<{ id?:number; label:string; link?:string }>;
  galleryModalVisible: boolean;
  forgotPasswordModalVisible: boolean;
  blogHomeBanners: BlogBanner[];
  // blogSideBanners: BlogBanner[];
  blogCategories: BlogCategory[];
  navCategories: BlogCategory[];
  firstCategory: BlogCategory;
  mostPopularArticles: Article[];
  selectedCategory: number|string;
  comments: Comment[]
}


class ArticleDetailsPage extends React.Component<ArticleDetailsPageProps, ArticleDetailsPageState> {

  userApi = new UserApi();
  articleApi = new ArticleApi()
  blogBannerApi = new BlogBannerApi();
  blogCategoryApi = new BlogCategoryApi();
  commentApi = new CommentApi();
  commentForm = React.createRef<FormInstance>();

  constructor(props) {
    super(props);
    this.state = {
      article: null,
      loggedOnUser: null,
      loginModalVisible: false,
      loginModalRedirectUrl: '',
      relatedArticles: [],
      breadcrumbsNavItems: [],
      galleryModalVisible: false,
      forgotPasswordModalVisible: false,
      blogHomeBanners: [],
      // blogSideBanners: [],
      blogCategories: [],
      navCategories: [],
      firstCategory: null,
      mostPopularArticles: [],
      selectedCategory: props.articleData.categories[0].id,
      comments: []
    };
  }

  componentDidMount(): void {
    this.mapIntoArticleObject(this.props.articleData);
    this.loadSignedInUserProfile();
    this.loadBanners();
    this.loadMostPopularArticles();
    this.loadBlogCategories();
    let countCategories = this.props.articleData.categories.length;
    let rand = Math.floor(Math.random() * countCategories);
    this.articleApi.increaseViewCount(this.props.articleData?.id);
    this.blogCategoryApi.getCategory(this.props.articleData.categories[0].id).
      then(result => {
        this.setState({firstCategory: result, selectedCategory: result.id});
      });
  }

  mapIntoArticleObject(articleData) {
    const article: Article = {
      id: articleData?.id,
      name: articleData?.name,
      publishedAt: articleData?.published_at? new Date(articleData?.published_at): null,
      content: articleData?.content,
      colors: articleData?.colors?.split(','),
      credits: articleData?.credits,
      slug: articleData?.slug,
      viewCount: articleData?.view_count,
      image: articleData?.image_url,
      categories: articleData?.categories.map(category => {
        return {
          id: category?.id,
          name: category?.name,
          ancestry: category?.ancestry,
          slug: category?.slug
        };
      }),
      comments: articleData?.comments.map(comment => {
        return {
          id: comment?.id,
          name: comment?.name,
          email: comment?.email,
          website: comment?.website,
          content: comment?.content,
          createdAt: comment?.created_at,
        };
      })
    };
    this.loadRelatedArticles(article.id)
    this.setState({ article: article, comments: article.comments });
  }

  loadSignedInUserProfile(): void {
    if(!this.props?.isAccountSignedIn) {
      return null;
    }
    
    this.userApi.getUserProfile()
    .then(user => {
      this.setState({
        loggedOnUser: user
      });
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'Error while loading the user\'s profile.', className: 'antd-mod center' });
    });
  }

  setBreadcrumbsNavItems(): void {
    this.setState({
      breadcrumbsNavItems: [
        { id: 1, label: 'Home', link: '/' },
        // { id: 2, label: this.state?.article?.category?.name, link: `/${this.state?.product?.category?.slug}` },
        // { id: 3, label: this.state?.product?.vendor?.businessName, link: `/${this.state?.product?.vendor?.slug}` },
        // { id: 4, label: this.state?.product?.name, link: '' },
      ]
    });
  }

  redirectToArticleDetails(id: number|string, slug: string): void {
    document.location = `/articles-by-weddingsmalaysia/articles/${id}/${slug}`;
  }

  redirectToArticleCategories(categoryId: number|string): void {
    this.blogCategoryApi.getCategory(categoryId).
    then(result => {
      document.location = `/articles-by-weddingsmalaysia/articles/c/${result.slug}`;
    })
  }

  loadRelatedArticles(articleId): void {
    this.articleApi.getArticles(null, articleId)
    .then(result => {
      this.setState({
        relatedArticles: result
      });
    });
  }

  loadBanners(): void {
    /*this.blogBannerApi.getSidebarBanners()
        .then(banners => {
          this.setState({blogSideBanners: banners});
        });*/
    this.blogBannerApi.getHomepageBanners()
        .then(banners => {
          this.setState({blogHomeBanners: banners});
        })
  }

  loadBlogCategories(): void {
    this.blogCategoryApi.getCategories(null)
        .then(result => {
          this.setState({ blogCategories: result });
        });
    this.blogCategoryApi.getCategories('nav')
        .then(result => {
          this.setState({ navCategories: result });
        });
  }

  renderHomeBanner(position:number) {
    if (!this.state?.blogHomeBanners) return;
    let banner = this.state?.blogHomeBanners[position];

    if (banner) {
        if (banner.link)
            return <Image loading='lazy' src={banner.image} preview={false} onClick={() => window.open(banner.link)} title={banner.link} style={{cursor: 'pointer'}} />;
        return <Image loading='lazy' src={banner.image} preview={false} />;
    }
    return;
  }

  /*renderSideBanner(position:number) {
    if (!this.state?.blogSideBanners) return;
    let banner = this.state?.blogSideBanners[position];

    if (banner) {
        if (banner.link)
            return <Image src={banner.image} preview={false} onClick={() => window.open(banner.link)} title={banner.link} style={{cursor: 'pointer'}} />;
        return <Image src={banner.image} preview={false} />;
    }
    return;
  }*/

  renderCategoryBanner(category, place, position) {
    if (!category?.banners) return;
    let banners = category.banners.filter(b => b.placing == place);

    if (banners[position]) {
      if (banners[position].link) {
        return (<Image loading='lazy'
                       src={banners[position].image}
                       preview={false}
                       onClick={() => {
                         this.blogBannerApi.increaseClickCount(banners[position].id);
                         window.open(banners[position].link);
                       }}
                       title={banners[position].link}
                       style={{cursor: 'pointer'}}
        />);
      }
      return (<Image loading='lazy'
                     src={banners[position].image}
                     onClick={() => {
                       this.blogBannerApi.increaseClickCount(banners[position].id);
                     }}
                     preview={false}
      />);
    }
    return;
  }

  renderCategoryTags() {
    return this.state.article?.categories?.map((category, categoryIndex) => {
      return <Tag className={`${categoryIndex === 0? 'primary': 'grey'}`} key={categoryIndex}>{category.name}</Tag>
    });
  }

  loadMostPopularArticles(): void {
    this.articleApi.getPopularArticles()
        .then(result => {
          this.setState({
            mostPopularArticles: result
          });
        });
  }

  renderSocialIcons() {
    return (
        <span>
          <Button className='social-button' danger shape="circle" icon={<HeartFilled />} />
          <Button className='social-button' danger shape="circle" icon={<FacebookFilled />} />
          <Button className='social-button' danger shape="circle" icon={<InstagramOutlined />} />
          <Button className='social-button' danger shape="circle" icon={<PinterestIcon style={{color: '#e55a67'}} />} />
          <Button className='social-button' danger shape="circle" icon={<MailFilled />} />
        </span>
    );
  }

  getParentIdFromAncestry(ancestry) {
    let pid = -1;
    if (ancestry !== null) {
      let ids = ancestry.split('/');
      pid = Number(ids[ids.length - 1]);
    }
    return pid;
  }

  generateCategoryTreeData(categories) {
    return categories?.map(category => {
      return {
        id: category.id.toString(),
        title: category.name,
        pId: this.getParentIdFromAncestry(category.ancestry).toString(),
        value: category.id.toString()
      }
    });
  }

  onChangeCategoryTree = (value) => {
    this.redirectToArticleCategories(value);
    this.setState({selectedCategory: value})
  }

  handleSearch = (val) => {
    document.location = encodeURI(`/articles-by-weddingsmalaysia/articles?search=${val}`);
  }

  submitForm = (values) => {
    let tmp = {name: values.name, email: values.email, website: values.website, content: values.content, article: this.state.article};
    if (!values.website) delete tmp['website'];
    this.commentApi.addComment(tmp)
        .then(result => {
          notification.success({ message: 'Saved successfully', className: 'antd-mod center' });
          this.loadComments();
          this.commentForm.current.resetFields();
        })
        .catch(error => {
          const errorKeys = Object.keys(error?.response?.data?.errors);
          errorKeys.forEach(errorKeyName => {
            const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
            notification.error({ message: errorMessage, className: 'antd-mod center' });
          });
        });
  }

  loadComments() {
    this.commentApi.getComments(this.state.article.id).then(result => {
      this.setState({comments: result});
    })
  }

  renderModals() {
    return (<>
      <LoginModal
          visible={this.state.loginModalVisible}
          googleSignInUrl={this.props?.googleSignInUrl}
          fbSignInUrl={this.props?.fbSignInUrl}
          csrfToken={this.props?.csrfToken}
          redirectUrl={this.state.loginModalRedirectUrl}
          forgotPasswordClicked={() => { this.setState({ loginModalVisible: false, forgotPasswordModalVisible: true }) }}
          closed={() => { this.setState({ loginModalVisible: false }) }}
          registerLink="/registration"
      />

      <ForgotPasswordModal
          visible={this.state.forgotPasswordModalVisible}
          loginClicked={() => { this.setState({ forgotPasswordModalVisible: false, loginModalVisible: true }) }}
          closed={() => { this.setState({ forgotPasswordModalVisible: false }) }}
      />

      {/*<GalleryModal
          visible={this.state.galleryModalVisible}
          imageUrls={this.state.article?.image}
          closed={() => { this.setState({ galleryModalVisible: false }) }}
      />*/}
    </>);
  }

  renderNavButton() {
    return (
        <div className="page-section">
          <div className="content">
            {this.renderHomeBanner(0)}
            {/*{this.renderCategoryBanner(this.state.firstCategory, 'homepage', 0)}*/}
            <ul className="ant-menu ant-menu-root ant-menu-horizontal ant-menu-light">
              {this.state.navCategories && this.state.navCategories.map(category => (
                <li className="ant-menu-overflow-item ant-menu-item ant-menu-item-only-child" key={category.id}>
                    <span className="ant-menu-title-content">
                      <a href={`/articles-by-weddingsmalaysia/articles/c/${category.slug}`}>{category.name}</a>
                    </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
    );
  }

  renderCategorySelect() {
    return (
        <>
          <div>
            <Divider orientation="left" className="blog-category-title">Categories</Divider>
          </div>
          <TreeSelect
              style={{ width: '100%' }}
              value={this.state.selectedCategory.toString()}
              treeDataSimpleMode
              treeData={this.generateCategoryTreeData(this.state.blogCategories)}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="Select category"
              treeDefaultExpandAll
              onChange={this.onChangeCategoryTree}
              switcherIcon={<DownOutlined />}
          />
        </>
    );
  }

  render() {
    /*const relatedArticles = this.state.relatedArticles?.map(
        article => (<ArticleCard article={article} url={`/articles-by-weddingsmalaysia/articles/${article.id}/${article.slug}`} clicked={() => { this.redirectToArticleDetails(article?.id, article?.slug) }} key={article.id} />)
    );*/

    return (
      <div className="ArticleDetailsPage">
        <Navbar
            isAccountSignedIn={this.props?.isAccountSignedIn}
            user={this.state?.loggedOnUser}
            activeNavItem={'articles-by-weddingsmalaysia'}
            signInRequired={(redirectUrl) => { this.setState({ loginModalVisible: true, loginModalRedirectUrl: redirectUrl }); }}
        />

        {this.renderModals()}

        {this.renderNavButton()}

        <div className="page-content">
          <div className="product-details-container">
            <div className="column-1">
              <div className="header-details">
                <div className="title-container">
                  <div className="title typo-headline typo-bold">{this.state.article?.name}</div>
                </div>

                <div className="additional-info">
                  {this.state.article?.publishedAt &&
                    <span className='date-text'>{ format(this.state.article?.publishedAt, 'MMMM dd, yyyy') }</span>
                  }
                  <Divider orientation='left' type='vertical' />
                  {this.renderCategoryTags()}
                 {/* <Divider orientation='left' type='vertical' />
                  {this.renderSocialIcons()}*/}
                </div>
              </div>

              <div className="detail-section article-content ql-editor">
                <div className="section-content typo-subheading-1" dangerouslySetInnerHTML={{__html: this.state.article?.content}} />
              </div>

              {/* Article Images */}
              {/*<div style={{display: 'block'}}>
                {this.state.article && this.state.article.imageUrls.map((url, index) => {
                  return <Image src={url} width={(this.state.article.imageUrls.length > 2 && index < 2)? '50%': '100%'} key={index} preview={false} onClick={() => this.setState({galleryModalVisible: true})} />
                })}
              </div>*/}
              {
                this.state.article?.credits && (
                    <div className="detail-section article-credits ql-editor">
                      <div className="section-content typo-subheading-1" dangerouslySetInnerHTML={{__html: this.state.article?.credits}}>
                      </div>
                    </div>
                )
              }

              <div>
                <Divider orientation="left" className="blog-category-title">Comments</Divider>
              </div>
              {/*  Comments */}
              <div className="detail-section article-comments">
                <div className='typo-subheading-2 typo-bold'>Leave a Comment</div>
                <Form
                    ref={this.commentForm}
                    name="comment-form"
                    className="comment-form"
                    initialValues={{ remember: true }}
                    autoComplete='off'
                    onFinish={this.submitForm}
                >
                  <Row gutter={10}>
                    <Col span={10}>
                      <Form.Item
                          name="name"
                          rules={[{ required: true, message: 'Please input your Name!' }]}
                      >
                        <Input placeholder="Name" size='large' />
                      </Form.Item>
                      <Form.Item
                          name="email"
                          rules={
                            [
                              { required: true, message: 'Please input your Email!' },
                              {type: 'email', message: 'Invalid Email!'}
                            ]
                          }
                      >
                        <Input type='email' placeholder="Email" size='large' />
                      </Form.Item>
                      <Form.Item
                          name="website"
                          rules={
                            [{ type: 'url', message: 'Invalid URL!' }]
                          }
                      >
                        <Input type='url' placeholder="Website" size='large' />
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      <Form.Item
                          name="content"
                          rules={[{ required: true, message: 'Please input your Comment!' }]}
                      >
                        <Input.TextArea placeholder="Comment" rows={7} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button danger htmlType="submit">Submit</Button>
                  </Form.Item>
                </Form>
                <Collapse accordion expandIconPosition='right' ghost>
                  <Collapse.Panel
                      header={<span className='typo-bold typo-subheading-2'>{`Comments(${this.state.comments.length})`}</span>}
                      key="1"
                  >
                    {this.state.comments.length > 0 && this.state.comments.map((comment, index) => {
                      return (
                          <>
                            <AntComment
                                key={index}
                                author={<a className='comment-author'>{comment.name}</a>}
                                avatar={<CommentOutlineIcon style={{color: '#e55a67'}} />}
                                content={
                                  <p>
                                    {comment.content}
                                  </p>
                                }
                                datetime={
                                  <span className='comment-date'>{format(new Date(comment.createdAt), 'MMMM dd, yyyy | h:mm a')}</span>
                                }
                            />
                            <Divider />
                          </>
                      );
                    })}
                  </Collapse.Panel>
                </Collapse>
              </div>

            </div>
            
            <div className="column-2">
              <Input.Search placeholder="Search articles" allowClear onSearch={this.handleSearch} />
              {this.renderCategorySelect()}
              {this.renderCategoryBanner(this.state.firstCategory, 'sidebar', 0)}
              {/* Blog Sidebar1 */}
              <div>
                <Divider orientation="left" className="blog-category-title">Most Popular Articles</Divider>
              </div>
              {this.state.mostPopularArticles?.map((article, index) => {
                return (
                    <Card
                        key={index}
                        onClick={() => { this.redirectToArticleDetails(article?.id, article?.slug) }}
                        hoverable
                        bordered={false}
                        cover={<img alt="article image" src={article.image} loading='lazy' />}
                    >
                      <Card.Meta title={article.name} description={format(article.publishedAt, 'MMM dd, yyyy')} />
                    </Card>
                )
              })}
              {/* Small ArticleCards */}
              {/* Blog Sidebar2 */}
              {this.renderCategoryBanner(this.state.firstCategory, 'sidebar', 1)}
              {this.renderCategoryBanner(this.state.firstCategory, 'sidebar', 2)}
              {/*<div>
                <Divider orientation="left" className="blog-category-title">Follow us on Instagram</Divider>
              </div>*/}
              {/* Small Images */}
            </div>
          </div>
          <div className="page-section">
            <div className="content">
              <Divider orientation="left" className="blog-category-title">Related Posts</Divider>
              {/*{relatedArticles && <ItemSlider title="" subtitle="" items={relatedArticles} />}*/}
              <Swiper
                  slidesPerView={3}
                  loop={true}
                  navigation={true}
                  className="item-slider"
                  spaceBetween={15}
                  centeredSlides={true}
                  centeredSlidesBounds={true}
                  freeMode
                  observer
                  breakpoints={
                    {
                      // when window width is >= 320px
                      320: {
                        slidesPerView: 1
                      },
                      // when window width is >= 480px
                      730: {
                        slidesPerView: 2
                      },
                      // when window width is >= 760px
                      1090: {
                        slidesPerView: 3
                      }
                    }
                  }
              >
                {this.state.relatedArticles?.map(article => (
                    <SwiperSlide key={article.id}>
                      <ArticleCard article={article}
                                   url={`/articles-by-weddingsmalaysia/articles/${article.id}/${article.slug}`}
                                   clicked={() => { this.redirectToArticleDetails(article?.id, article?.slug) }}
                      />
                    </SwiperSlide>)
                )}
              </Swiper>
            </div>
          </div>
        </div>

        <Footer userType={this.state.loggedOnUser?.type} />
      </div>
    );
  }
}

export default ArticleDetailsPage;
