import axios from 'axios';
import {Article} from "../interfaces/article.interface";

class ArticleAdminApi {

  getArticles(page, per_page): Promise<{ articles: Article[], total: number }>{
    const apiUrl = `/api/v1/admin/articles?per_page=${per_page}&page=${page}`;

    return axios.get(apiUrl)
    .then(response => {
      const articles: Article[] = [];
      response?.data?.data?.forEach(articlesData => {
        articles.push(this.mapArticleResponse(articlesData?.attributes));
      });
      
      return Promise.resolve({articles, total: response?.data?.meta?.total_count});
    });
  }

  getRelatedArticles(related_to: number): Promise<Article[]>{
    let apiUrl = `/api/v1/admin/articles?`;
    if (related_to !== null) apiUrl += `related_to=${related_to}`

    return axios.get(apiUrl)
        .then(response => {
          const articles: Article[] = [];
          response?.data?.data?.forEach(articlesData => {
            articles.push(this.mapArticleResponse(articlesData?.attributes));
          });

          return Promise.resolve(articles);
        });
  }

  getArticle(id:number): Promise<Article>{
    const apiUrl = `/api/v1/admin/articles/${id}`;

    return axios.get(apiUrl)
        .then(response => {
          const article: Article = this.mapArticleResponse(response?.data?.data?.attributes);

          return Promise.resolve(article);
        });
  }


  addArticle(articleData:FormData): Promise<Article>{
    const apiUrl = `/api/v1/admin/articles`;
    
    return axios({
      method: "post",
      url: apiUrl,
      data: articleData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response)=> {
      const article:Article = this.mapArticleResponse(response?.data?.data?.attributes);
      return article;
    })
  }

  updateArticle(articleId:number, articleData:FormData): Promise<Article>{
    const apiUrl = `/api/v1/admin/articles/${articleId}`;

    return axios({
      method: "put",
      url: apiUrl,
      data: articleData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response)=> {
      const article:Article = this.mapArticleResponse(response?.data?.data?.attributes);
      return article;
    })
  }


  removeArticle(article:number){
    const apiUrl = `/api/v1/admin/articles/${article}`;
    return axios.delete(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  publishArticle(article:number){
    const apiUrl = `/api/v1/admin/articles/${article}/publish`;
    return axios.patch(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  archiveArticle(article:number){
    const apiUrl = `/api/v1/admin/articles/${article}/reject`;
    return axios.patch(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  increaseViewCount(article:number){
    const apiUrl = `/api/v1/admin/articles/${article}/increase-view-count`;
    return axios.patch(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  getImages(articleId: any): Promise<{id: number|string, imageUrl: string}[]>{
    const apiUrl = `/api/v1/admin/articles/${articleId}/images`;

    return axios.get(apiUrl)
        .then(response =>{
          const images:{id: number|string, imageUrl: string}[] = [];
          response?.data?.data?.forEach((imageResponse: any) => {
            images.push({
              id: imageResponse?.attributes?.id,
              imageUrl: imageResponse?.attributes?.image_url,
            })
          });
          return Promise.resolve(images);
        })
  }

  deleteImage(articleId: any, id: number): Promise<any>{
    const apiUrl = '/api/v1/admin/articles/'+articleId+'/images/'+id;

    return axios.delete(apiUrl)
        .then(response =>{
          return Promise.resolve(true);
        })
  }

  createImage(articleId: any, image: any): Promise<any>{
    const apiUrl = `/api/v1/admin/articles/${articleId}/images/`;
    const formData = new FormData();

    formData.append('image', image);

    return axios({
      method: "post",
      url: apiUrl,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response)=> {
        return response?.data?.data?.attributes?.image_url
      });
  }


  mapArticleResponse(articleData){
    return {
      id: articleData?.id,
      name: articleData?.name,
      description: articleData?.description,
      keywords: articleData?.keywords,
      publishedAt: articleData?.published_at? new Date(articleData?.published_at): null,
      content: articleData?.content,
      colors: articleData?.colors?.split(','),
      credits: articleData?.credits,
      viewCount: articleData?.view_count,
      image: articleData?.image_url,
      mayPublish: articleData?.may_publish,
      mayReject: articleData?.may_reject,
      categories: articleData?.categories.map(category => {
        return {
          id: category?.id,
          name: category?.name,
          ancestry: category?.ancestry
        };
      }),
      tags: articleData?.tags.map(tag => {
        return {
          id: tag?.id,
          name: tag?.name,
          slug: tag?.slug,
          status: tag?.status,
          createdAt: tag?.created_at,
        };
      }),
      comments: articleData?.comments.map(comment => {
        return {
          id: comment?.id,
          name: comment?.name,
          email: comment?.email,
          website: comment?.website,
          content: comment?.content,
          status: comment?.status
        };
      }),
    };
  }
}

export default ArticleAdminApi;