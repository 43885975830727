import axios from 'axios';
import {Article} from "../interfaces/article.interface";

class ArticleApi {

  getArticles(category_id: number, related_to: number): Promise<Article[]>{
    let apiUrl = `/api/v1/articles`;
    if (category_id !== null || related_to !== null/* || limit !== null*/) apiUrl += '?';
    if (category_id !== null) apiUrl += `category_id=${category_id}&`
    if (related_to !== null) apiUrl += `related_to=${related_to}&`
    // if (limit !== null) apiUrl += `limit=${limit}&`

    return axios.get(apiUrl)
    .then(response => {
      const articles: Article[] = [];
      response?.data?.data?.forEach(articlesData => {
        articles.push(this.mapArticleResponse(articlesData?.attributes));
      });
      
      return Promise.resolve(articles);
    });
  }

  searchArticles(search, page, per_page): Promise<{ articles: Article[], total: number }>{
    let apiUrl = `/api/v1/articles?search=${search}&per_page=${per_page}&page=${page}`;

    return axios.get(apiUrl)
        .then(response => {
          const articles: Article[] = [];
          response?.data?.data?.forEach(articlesData => {
            articles.push(this.mapArticleResponse(articlesData?.attributes));
          });

          return Promise.resolve({articles, total: response?.data?.meta?.total_count});
        });
  }

  searchArticlesByName(search): Promise<Article[]>{
    let apiUrl = `/api/v1/articles?search_name=${search}&per_page=${100}&page=${1}`;

    return axios.get(apiUrl)
      .then(response => {
        const articles: Article[] = [];
        response?.data?.data?.forEach(articlesData => {
          articles.push(this.mapArticleResponse(articlesData?.attributes));
        });

        return Promise.resolve(articles);
      });
  }

  getPopularArticles(): Promise<Article[]>{
    let apiUrl = `/api/v1/articles/popular?limit=3`;

    return axios.get(apiUrl)
        .then(response => {
          const articles: Article[] = [];
          response?.data?.data?.forEach(articlesData => {
            articles.push(this.mapArticleResponse(articlesData?.attributes));
          });

          return Promise.resolve(articles);
        });
  }

  getRecentArticles(): Promise<Article[]>{
    let apiUrl = `/api/v1/articles/recent?limit=6`;

    return axios.get(apiUrl)
        .then(response => {
          const articles: Article[] = [];
          response?.data?.data?.forEach(articlesData => {
            articles.push(this.mapArticleResponse(articlesData?.attributes));
          });

          return Promise.resolve(articles);
        });
  }

  getArticle(id:number): Promise<Article>{
    const apiUrl = `/api/v1/articles/${id}`;

    return axios.get(apiUrl)
        .then(response => {
          const article: Article = this.mapArticleResponse(response?.data?.data?.attributes);

          return Promise.resolve(article);
        });
  }

  increaseViewCount(article:number){
    const apiUrl = `/api/v1/articles/${article}/increase-view-count`;
    return axios.patch(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  mapArticleResponse(articleData){
    return {
      id: articleData?.id,
      name: articleData?.name,
      publishedAt: articleData?.published_at? new Date(articleData?.published_at): null,
      content: articleData?.content,
      colors: articleData?.colors?.split(','),
      credits: articleData?.credits,
      viewCount: articleData?.view_count,
      image: articleData?.image_url,
      slug: articleData?.slug,
      categories: articleData?.categories.map(category => {
        return {
          id: category?.id,
          name: category?.name,
          ancestry: category?.ancestry,
          slug: category?.slug
        };
      }),
      tags: articleData?.tags.map(tag => {
        return {
          id: tag?.id,
          name: tag?.name,
          slug: tag?.slug,
          status: tag?.status,
          createdAt: tag?.created_at,
        };
      }),
      comments: articleData?.comments.map(comment => {
        return {
          id: comment?.id,
          name: comment?.name,
          email: comment?.email,
          website: comment?.website,
          content: comment?.content,
        };
      }),
    };
  }
}

export default ArticleApi;