import { Button, Modal, notification, Table } from "antd";
import * as moment from "moment";
import * as React from "react"
import ProductAdminApi from "../../../api-services/product-admin-api.service";
import { Product } from "../../../interfaces/product.interface";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";


interface IAdminProductsTableProps {
  products:Product[];
  selectedProducts:number[];
  approveProduct: (id:number)=>void;
  rejectProduct: (id:number)=>void;
  selectionChange: (selectedRows)=>void;
  onChange: (pagination:any)=>void;
  pagination: any;
  hideAction: boolean;
}

interface IAdminProductsTableState {
  columns: any[];
  modalVisible:boolean;
  selectedProduct:Product;
}

class AdminProductsTable extends React.Component <IAdminProductsTableProps, IAdminProductsTableState> {

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      selectedProduct:null,
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          render: (text, record) => {
            const productEditLink = `/admin/products/${record.id}`;
            return <a href={productEditLink}>{text}</a>
            // return <a onClick={()=>this.getProductInfo(record.id)}>{text}</a>
          }
        },
        {
          title: 'Vendor',
          dataIndex: 'vendor',
          render: (text, record) => <span>{record.vendor.businessName}</span>
        },
        {
          title: 'Status',
          dataIndex: 'status',
          render: (text, record) => {
            // const statusInfo = text=='rejected' ? `${text}-${record.remarks}` : text;
            return <span className="status">{text}</span>
          }
        }
      ]
    };
  }

  componentDidMount(){
    if (this.props?.hideAction){
      this.setState({
        columns: [
          {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
              const productEditLink = `/admin/products/${record.id}`;
              return <a href={productEditLink}>{text}</a>
              // return <a onClick={()=>this.getProductInfo(record.id)}>{text}</a>
            }
          },
          {
            title: 'Vendor',
            dataIndex: 'vendor',
            render: (text, record) => <span>{record.vendor.businessName}</span>
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
              // const statusInfo = text=='rejected' ? `${text}-${record.remarks}` : text;
              return <span className="status">{text}</span>
            }
          }
        ]
      });
    } else {
      this.setState({
        columns: [
          {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
              const productEditLink = `/admin/products/${record.id}`;
              return <a href={productEditLink}>{text}</a>
              // return <a onClick={()=>this.getProductInfo(record.id)}>{text}</a>
            }
          },
          {
            title: 'Vendor',
            dataIndex: 'vendor',
            render: (text, record) => <span>{record.vendor.businessName}</span>
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
              // const statusInfo = text=='rejected' ? `${text}-${record.remarks}` : text;
              return <span className="status">{text}</span>
            }
          },
          {
            title: 'Action',
            key: 'operation',
            width: 200,
            render: (text, record) => {
              return <>
                <Button type="link" disabled={!record.mayApprove} onClick={()=>this.props?.approveProduct(record.id)}>Approve</Button>
                <Button type="link" disabled={!record.mayReject} danger onClick={()=>this.props?.rejectProduct(record.id)}>Reject</Button>
              </>
            },
          },
        ]
      });
    }
  }

  getProductInfo(productId:number){
    const productApi = new ProductAdminApi;
    productApi.getProduct(productId)
      .then(productResponse => {
        this.setState({modalVisible: true, selectedProduct: productResponse});
      })
      .catch(error => {
        notification.error({ message: 'Error while fetching product info', className: 'antd-mod center' });
      });
  }
  

  renderDetailsModal(){
    const product = this.state?.selectedProduct;

    return (
      <Modal className="ProductDetailsModal" title={<span className="title typo-title">{product?.type} Details</span>} visible={this.state?.modalVisible} onOk={()=>this.setState({modalVisible:false})} cancelButtonProps={{ style: { display: 'none' } }}>
        <div className="typo-small">Name: {product?.name}</div>
        <div className="typo-small">Sub-category: {product?.category?.name}</div>
        <div className="typo-small">Campaign: {product?.campaign?.title}</div>
        { product?.availabilityStart != null && <div className="typo-small">Selling Period: {moment(product?.availabilityStart).format('DD/MM/YYYY') + " - " + moment(product?.availabilityEnd).format('DD/MM/YYYY')}</div>}
        <div className="typo-small">Product Details: 
          <br/>
          <div className="description" dangerouslySetInnerHTML={{__html: product?.details}}></div>
        </div>
        <div className="typo-small">Terms & Conditions: 
          <br/>
          <div className="description" dangerouslySetInnerHTML={{__html: product?.terms}}></div>
        </div>
        <div className="typo-small">Price: RM {product?.price}</div>
        <div className="typo-small">Discounted Price: RM {product?.discountedPrice}</div>
        <div className="typo-small">Unit: {product?.unit}</div>
        { product?.requirePartialPayment && <div className="typo-small">Deposit: {product?.partialPaymentType == 'fixed' ? `${product?.partialPaymentType} ${product?.partialPayment}` : `${product?.partialPayment} ${product?.partialPaymentType}` } </div>}
        <div className="typo-small">Payment Terms: {product?.paymentTerms}</div>
        <div className="typo-small">SKU: {product?.sku}</div>
        { product?.trackInventory && <div className="typo-small">Quantity: {product?.quantity}</div>}
        <div className="typo-small">Flexi Reschedule: {product?.easyReschedule.toString()}</div>
        <div className="typo-small">Required Service Date: {product?.requiredServiceDate.toString()}</div>
        <div className="typo-small">Required Service Location: {product?.requiredServiceLocation.toString()}</div>
        {/* <div className="typo-small">
            Outstation Fees:
            {product?.outstationFees?.map(fees => {
              return [fees.]
            })}
        </div> */}
        <div className="typo-small">
            Variants:
            {product?.variants?.map(variant => {
              return (
                <>
                  <br/>
                  <span>{variant.option} - RM{variant.price}</span>
                </>
              )
            })}
        </div>
      </Modal>
    )
  }


  render() {
    const rowSelection = {
      // selectedRowKeys: this.props?.selectedProducts,
      preserveSelectedRowKeys: false,
      onChange: (selectedRowKeys, selectedRows) => {
        this.props?.selectionChange(selectedRowKeys);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    return (
      <div className="AdminProductsTable">
        {this.renderDetailsModal()}

        <Table size="small" bordered rowKey="id"
          // rowSelection={{
          //   type: "checkbox",
          //   ...rowSelection,
          //   selectedRowKeys: this.props?.selectedProducts
          // }}
          columns={this.state?.columns}
          dataSource={this.props?.products}
          pagination={this.props?.pagination}
          onChange={this.props?.onChange}
        />
      </div>
    );
  }
}

export default AdminProductsTable
