import axios from "axios";
import { OutstationFee } from "../interfaces/outstation-fee";
import { ProductVariant } from "../interfaces/product-variant.interface";
import { Product, ProductImage } from "../interfaces/product.interface";

interface SaveProductPayload {
  name: string;
  type: string;
  details?: string;
  description?: string;
  price?: number;
  discountedPrice?: number;
  terms?: string;
  paymentTerms?: string;
  status?: string;
  sku?: string;
  trackInventory?: boolean;
  quantity?: number;
  isPhysical?: boolean;
  requirePartialPayment?: boolean;
  partialPayment?: number;
  partialPaymentType?: string;
  cutOffDays?: number;
  campaign?:number; //campaign id
  unit?:string;
  minPerBooking?:number;
  flexiReschedule?:boolean;
  categories?:number[];
  sellingPeriodStart?:string;
  sellingPeriodEnd?:string;
  outstationFees?:OutstationFee[];
  option?:string;
  variants?:ProductVariant[];
  calendarId?:number;
  publish?: boolean;
  nonMuslim?: boolean;
  above21?: boolean;
  requiredServiceDate?: boolean;
  requiredServiceLocation?: boolean;
}

class ProductApi {

  getProducts(): Promise<Product[]> {
    const apiUrl = `/api/v1/products/my`;

    return axios.get(apiUrl)
    .then((response: any) => {
      const products: Product[] = [];

      response?.data?.data?.forEach((productData: any) => {
        products.push({
          id: productData.attributes?.id,
          image: productData.attributes?.cover_image_url,
          unit: productData.attributes?.unit,
          name: productData.attributes?.name,
          type: productData.attributes?.kind,
          status: productData.attributes?.status,
          price: productData.attributes?.price,
          discountedPrice: productData.attributes?.discounted_price,
          vendor: {
            id: productData?.attributes?.vendor?.id,
            businessName: productData?.attributes?.vendor?.name,
            slug: productData?.attributes?.vendor?.slug,
          },
          campaign: productData?.attributes?.campaign,
          mayDelete: productData?.attributes?.may_delete,
          // categories: []
        });
      })
      
      return products;
    });
  }
  

  getProduct(productId: any): Promise<Product> {
    const apiUrl = `/api/v1/products/my/${productId}?`;

    return axios.get(apiUrl)
    .then(response => {      
      const product:Product = {
        id: response?.data?.data?.attributes?.id,
        name: response?.data?.data?.attributes?.name,
        unit: response?.data?.data?.attributes?.unit,
        minPerBooking: response?.data?.data?.attributes?.min_per_booking,
        type: response?.data?.data?.attributes?.kind,
        status: response?.data?.data?.attributes?.status,
        sku: response?.data?.data?.attributes?.sku,
        details: response?.data?.data?.attributes?.details,
        description: response?.data?.data?.attributes?.description,
        terms: response?.data?.data?.attributes?.terms_and_conditions,
        paymentTerms: response?.data?.data?.attributes?.payment_terms,
        price: response?.data?.data?.attributes?.price,
        discountPercentage: response?.data?.data?.attributes?.discounted_price,
        trackInventory: response?.data?.data?.attributes?.track_inventory,
        quantity: response?.data?.data?.attributes?.total_qty_on_hand,
        isPhysical: response?.data?.data?.attributes?.is_physical,
        partialPaymentType: response?.data?.data?.attributes?.partial_payment_type,
        partialPayment: response?.data?.data?.attributes?.required_partial_payment,
        requirePartialPayment: response?.data?.data?.attributes?.partial_payment_required,
        cutOffDays: response?.data?.data?.attributes?.cut_off_days,
        easyReschedule: response?.data?.data?.attributes?.flexi_reschedule,
        campaign: response?.data?.data?.attributes?.campaign?.id,
        subCategories: response?.data?.data?.attributes?.subcategories?.map(c => c.id),
        availabilityStart: response?.data?.data?.attributes?.availability_start,
        availabilityEnd: response?.data?.data?.attributes?.availability_end,
        outstationFees: response?.data?.data?.attributes?.outstation_fees?.map(o => {
          const location = o.parent_locations || [];
          location.push(o.location_id);
          return {location: location, fee: o.fee}
        }),
        option: response?.data?.data?.attributes?.option,
        optionValues: response?.data?.data?.attributes?.option_values,
        calendarId: response?.data?.data?.attributes?.calendar_id,
        variants: response?.data?.data?.attributes?.variants.map(variant => {
          return {
            id: variant?.id,
            option: variant?.option_value,
            details: variant?.details,
            price: variant?.price,
            discountedPrice: variant?.discounted_price,
            savingAmount: variant?.discount_amount,
            stock: variant?.total_qty_on_hand,
            sku: variant?.sku,  
            trackInventory: variant?.track_inventory,
            calendarId: variant?.calendar_id
          };
        }),
        nonMuslim: response?.data?.data?.attributes?.non_muslim_only,
        above21: response?.data?.data?.attributes?.above_twenty_one_only,
        requiredServiceDate: response?.data?.data?.attributes?.required_service_date,
        requiredServiceLocation: response?.data?.data?.attributes?.required_service_location,
        category: {
          id: response?.data?.data?.attributes?.category?.id,
          name: response?.data?.data?.attributes?.category?.name,
        },
        mayPublish: response?.data?.data?.attributes?.may_publish,
        mayArchive: response?.data?.data?.attributes?.may_archive,
      }     
      return Promise.resolve(product);
    });
  }


  /**
   * Get the list of Products with some filters.
   * DEV NOTE: Additional filters might be needed in the future. Simply add a new parameter for that filter but please keep "itemsPerPage" to be the last parameter.
   */
  getFilteredProducts(campaignId?: number, vendorSlug?: string, relatedTo?: string, country?: string, city?: string, category?: string, page: number = 1, itemsPerPage: number = 20): Promise<Product[]> {
    let apiUrl = `/api/v1/products?per_page=${itemsPerPage}&page=${page}`;

    if (campaignId) apiUrl += `&campaign_id=${campaignId}`;
    if (vendorSlug) apiUrl += `&vendor=${vendorSlug}`;
    if (relatedTo) apiUrl += `&related_to=${relatedTo}`;
    if (category) apiUrl += `&category_id=${category}`;
    if (country) apiUrl += `&country=${country}`;
    if (city) apiUrl += `&city=${city}`;


    return axios.get(apiUrl)
    .then((response: any) => {
      const products: Product[] = [];      
      response?.data?.data?.forEach(item => {
        products.push({
          id: item?.attributes?.id,
          image: item?.attributes?.cover_image_url,
          discountAmount: item?.attributes?.discount_amount,
          discountPercentage: item?.attributes?.discount_percent,
          discountedPrice: item?.attributes?.discounted_price,
          easyReschedule: item?.attributes?.flexi_reschedule,
          name: item?.attributes?.name,
          unit: item?.attributes?.unit,
          price: item?.attributes?.price,
          sku: item?.attributes?.sku,
          slug: item?.attributes?.slug,
          status: item?.attributes?.status,
          quantity: item?.attributes?.total_qty_on_hand,
          rating: item?.attributes?.rating,
          wishlisted: item?.attributes?.like,
          category: {
            id: item?.attributes?.category?.id,
            name: item?.attributes?.category?.name,
            slug: item?.attributes?.category?.slug,
          },
          subCategories: item?.attributes?.subcategories?.map(subCategory => {
            return {
              id: subCategory?.id,
              name: subCategory?.name,
              slug: subCategory?.slug,
            };
          }),
          vendor: {
            id: item?.attributes?.vendor?.id,
            businessName: item?.attributes?.vendor?.name,
            slug: item?.attributes?.vendor?.slug,
          },
          location: {
            id: item?.attributes?.location?.id,
            name: item?.attributes?.location?.name,
            slug: item?.attributes?.location?.slug,
            has_children: item?.attributes?.location?.has_children
          },
          campaign: {
            id: item?.attributes?.campaign?.id
          }
        });
      });
      
      return Promise.resolve(products);
    });
  }

  searchProductsByName(search: string): Promise<Product[]> {
    let apiUrl = `/api/v1/products?search=${search}&per_page=${100}&page=${1}`;

    return axios.get(apiUrl)
      .then((response: any) => {
        const products: Product[] = [];
        response?.data?.data?.forEach(item => {
          products.push({
            id: item?.attributes?.id,
            name: item?.attributes?.name,
            slug: item?.attributes?.slug,
            status: item?.attributes?.status
          });
        });

        return Promise.resolve(products);
      });
  }


  getWishlistedProducts(): Promise<Product[]> {
    const apiUrl = `api/v1/wishlist`;

    return axios.get(apiUrl)
    .then((response: any) => {
      const products: Product[] = [];      
      response?.data?.data?.forEach(item => {
        products.push({
          id: item?.attributes?.id,
          image: item?.attributes?.cover_image_url,
          discountAmount: item?.attributes?.discount_amount,
          discountPercentage: item?.attributes?.discount_percent,
          discountedPrice: item?.attributes?.discounted_price,
          easyReschedule: item?.attributes?.flexi_reschedule,
          name: item?.attributes?.name,
          unit: item?.attributes?.unit,
          price: item?.attributes?.price,
          sku: item?.attributes?.sku,
          slug: item?.attributes?.slug,
          status: item?.attributes?.status,
          quantity: item?.attributes?.total_qty_on_hand,
          rating: item?.attributes?.rating,
          wishlisted: item?.attributes?.like,
          category: {
            id: item?.attributes?.category?.id,
            name: item?.attributes?.category?.name,
            slug: item?.attributes?.category?.slug,
          },
          subCategories: item?.attributes?.subcategories?.map(subCategory => {
            return {
              id: subCategory?.id,
              name: subCategory?.name,
              slug: subCategory?.slug,
            };
          }),
          vendor: {
            id: item?.attributes?.vendor?.id,
            businessName: item?.attributes?.vendor?.name,
            slug: item?.attributes?.vendor?.slug,
          },
          location: {
            id: item?.attributes?.location?.id,
            name: item?.attributes?.location?.name,
            slug: item?.attributes?.location?.slug,
            has_children: item?.attributes?.location?.has_children
          },
          campaign: {
            id: item?.attributes?.campaign?.id
          }
        });
      });
      
      return Promise.resolve(products);
    });
  }


  getImages(productId: any): Promise<ProductImage[]>{
    const apiUrl = `/api/v1/products/my/${productId}/images`;

    return axios.get(apiUrl)
      .then(response =>{
        const images:ProductImage[] = [];
        response?.data?.data?.forEach((imageResponse: any) => {
          images.push({
            id: imageResponse?.attributes?.id,
            imageUrl: imageResponse?.attributes?.image_url,
          })
        });
        return Promise.resolve(images);
      })
  }

  deleteImages(productId: any, id: number): Promise<any>{
    const apiUrl = '/api/v1/products/my/'+productId+'/images/'+id;

    return axios.delete(apiUrl)
      .then(response =>{
        return Promise.resolve(true);
      })
  }


  createProduct(): Promise<number> {
    const apiUrl = `/api/v1/products/my`;

    return axios.post(apiUrl)
    .then(response => {
      const newProductId = response?.data?.data?.attributes?.id;
      return Promise.resolve(newProductId);
    });
  }



  updateProduct(productId: any, payload: SaveProductPayload): Promise<Product> {
    const apiUrl = `/api/v1/products/my/${productId}`;

    var variants = [];
    if(payload.variants){      
      variants = payload.variants.map(variant => {
        return {
          id: variant.id,
          option: variant.option,
          details: variant.details,
          sku: variant.sku,
          stock: variant.stock,
          price: variant.price,
          discounted_price: variant.discountedPrice,
          track_inventory: variant.trackInventory,
          calendar_id: variant.calendarId
        }
      })
    }

    return axios.put(apiUrl, {
      product: {
        name: payload.name,
        kind: payload.type,
        unit: payload.unit,
        min_per_booking: payload.minPerBooking,
        details: payload.details,
        description: payload.description,
        payment_terms: payload.paymentTerms,
        terms_and_conditions: payload.terms,
        price: payload.price,
        discounted_price: payload.discountedPrice,
        status: payload.status,
        sku: payload.sku,
        track_inventory: payload.trackInventory,
        stock: payload.quantity,
        is_physical: payload.isPhysical,
        required_partial_payment: payload.partialPayment,
        partial_payment_required: payload.requirePartialPayment,
        partial_payment_type: payload.partialPaymentType,
        cut_off_days: payload.cutOffDays,
        campaign_id: payload.campaign,
        flexi_reschedule: payload.flexiReschedule,
        category_ids: payload.categories,
        availability_start: payload.sellingPeriodStart,
        availability_end: payload.sellingPeriodEnd,
        option: payload.option,
        variants: variants,
        publish: payload.publish,
        outstation_fees: payload.outstationFees?.map(o => {
          return {location_id: o.locationId, fee: o.fee}
        }),
        calendar_id: payload.calendarId,
        non_muslim_only: payload.nonMuslim,
        above_twenty_one_only: payload.above21,
        required_service_date: payload.requiredServiceDate,
        required_service_location: payload.requiredServiceLocation
      }
    })
    .then(response => {
      const product:Product = {
        id: response?.data?.data?.attributes?.id,
        name: response?.data?.data?.attributes?.name,
      }
      
      return Promise.resolve(product);
    });
  }


  publishProduct(productId){
    const apiUrl = `/api/v1/products/my/${productId}/publish`;
    return axios.patch(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  deleteProduct(productId){
    const apiUrl = `/api/v1/products/my/${productId}`;
    return axios.delete(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  archiveProduct(productId){
    const apiUrl = `/api/v1/products/my/${productId}/archive`;
    return axios.patch(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }

  deleteVariant(productId, variantId){
    const apiUrl = `/api/v1/products/my/${productId}/variants/${variantId}`;
    return axios.delete(apiUrl).then(response => {
      return Promise.resolve(true);
    });
  }


  updateWishlistStatus(productId: number|string, wishlistStatus: boolean): Promise<any> {
    const apiUrl = `/api/v1/likes`;
    const payload = {
      like: {
        type: 'Product',
        like: wishlistStatus,
        id: productId
      }
    };

    return axios.post(apiUrl, payload).then(response => {
      return Promise.resolve(response);
    });
  }
  
}

export default ProductApi;